import React, { useEffect, useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Lightbox from 'react-image-lightbox';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import Button from '@mui/material/Button';

import Grid from '@mui/material/Grid';
import Main from 'layouts/Main';

// import p1 from 'assests/Photos/ClassAssembly/1C/1.jpg';
// import p2 from 'assests/Photos/ClassAssembly/1C/2.jpg';
// import p3 from 'assests/Photos/ClassAssembly/1C/3.jpg';
// import p4 from 'assests/Photos/ClassAssembly/1C/4.jpg';
// import p5 from 'assests/Photos/ClassAssembly/1C/5.jpg';
// import p6 from 'assests/Photos/ClassAssembly/1C/6.jpg';
// import p7 from 'assests/Photos/ClassAssembly/1C/7.jpg';
// import p8 from 'assests/Photos/ClassAssembly/1C/8.jpg';




import { Typography } from '@mui/material';
import Container from 'components/Container';
import SidebarArticles from 'views/ClassAssembly3E/components/SidebarArticles/SidebarArticles';

const ClassAssembly1C = () => {
  const theme = useTheme();
  const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);

  const openLightbox = (index) => {
    setCurrentImage(index);
    setViewerIsOpen(true);
  };

  const closeLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
  };

  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  const [config, setConfig] = useState({ base_image_archive_url: '' });
  useEffect(() => {
    fetch('/config.json')  
      .then(response => response.json())
      .then(data => setConfig(data))
      .catch(error => console.error('Error fetching config:', error));
  }, []);
  
  const p1 = `${config.base_image_archive_url}/academic-year-2022-23/home/events-activities/class-assembly/1C/1.webp`;
  const p2 = `${config.base_image_archive_url}/academic-year-2022-23/home/events-activities/class-assembly/1C/2.webp`;
  const p3 = `${config.base_image_archive_url}/academic-year-2022-23/home/events-activities/class-assembly/1C/3.webp`;
  const p4 = `${config.base_image_archive_url}/academic-year-2022-23/home/events-activities/class-assembly/1C/4.webp`;
  const p5 = `${config.base_image_archive_url}/academic-year-2022-23/home/events-activities/class-assembly/1C/5.webp`;
  const p6 = `${config.base_image_archive_url}/academic-year-2022-23/home/events-activities/class-assembly/1C/6.webp`;
  const p7 = `${config.base_image_archive_url}/academic-year-2022-23/home/events-activities/class-assembly/1C/7.webp`;
  const p8 = `${config.base_image_archive_url}/academic-year-2022-23/home/events-activities/class-assembly/1C/8.webp`;
  
  const photos = [
    {
      src: p1,
      source: p1,
      rows: 1,
      cols: 1,
    },
    {
      src: p2,
      source:p2,
      rows: 1,
      cols: 1,
    },
    {
      src: p3,
      source: p4,
      rows: 1,
      cols: 1,
    },
    {
      src: p4,
      source: p4,
      rows: 1,
      cols: 1,
    },
    {
        src: p5,
        source: p5,
        rows: 1,
        cols: 1,
      },
      {
        src: p6,
        source: p6,
        rows: 1,
        cols: 1,
      },
      {
        src: p7,
        source: p7,
        rows: 1,
        cols: 1,
      },
      {
        src: p8,
        source: p8,
        rows: 1,
        cols: 1,
      },
    // {
    //   src: 'https://assets.maccarianagency.com/backgrounds/img21.jpg',
    //   source: 'https://assets.maccarianagency.com/backgrounds/img21.jpg',
    //   rows: 1,
    //   cols: 1,
    // },
  ];

  return (
    <Main>
        <Container>
    <Grid container spacing={4}>
            <Grid item xs={12} md={8}>
    <Box>
        
      <Box >
      <Typography variant='h4' align='center'>
      Winter
        </Typography>
        <Typography
        fontSize = "x-small"
        ><br/><br/>
     Class 1C Thursday, 8 December 2022 and Friday, 9 December 2022.
        </Typography>

        <br />
        <Typography variant={'subtitle1'} align={'justify'}>
        Students of Class 1C presented a special assembly on the topic “Winter” on Thursday, 8
December 2022 and Friday, 9 December 2022. The assembly began with a prayer followed
by the thought for the day. Students spoke about the beauty and characteristics of the
season, explaining that winter is the most beautiful time of the year. It is the coldest
season which occurs after autumn and before spring. Foggy mornings, cold air and quiet
weather are all what people enjoy during winters.
<br></br>
They expressed their joy and happiness to welcome the winter season through a
mesmerising dance. A tabla performance captivated the audience.
        </Typography>
      </Box>
     

      <Box>
        <ImageList
          variant="quilted"
          cols={3}
          rowHeight={isMd ? 300 : 200}
          gap={isMd ? 16 : 4}
        >
          {photos.map((item, i) => (
            <ImageListItem key={i} cols={item.cols} rows={item.rows}>
              <LazyLoadImage
                height={'100%'}
                width={'100%'}
                src={item.src}
                alt="..."
                effect="blur"
                onClick={() => openLightbox(i)}
                style={{
                  objectFit: 'cover',
                  filter:
                    theme.palette.mode === 'dark' ? 'brightness(0.7)' : 'none',
                  cursor: 'poiner',
                  borderRadius: 8,
                }}
              />
            </ImageListItem>
          ))}
        </ImageList>
      </Box>
      {viewerIsOpen && (
        <Lightbox
          mainSrc={photos[currentImage].src}
          nextSrc={photos[(currentImage + 1) % photos.length].src}
          prevSrc={
            photos[(currentImage + photos.length - 1) % photos.length].src
          }
          onCloseRequest={() => closeLightbox()}
          onMovePrevRequest={() =>
            setCurrentImage((currentImage + photos.length - 1) % photos.length)
          }
          onMoveNextRequest={() =>
            setCurrentImage((currentImage + 1) % photos.length)
          }
          reactModalStyle={{ overlay: { zIndex: 1500 } }}
        />
      )}
    </Box>
    <Typography
          variant={'h4'}
          color={'primary'}
          align={'center'}
          fontWeight={'normal'}
        ><br/>It seems like everything sleeps in winter, but its really a time of renewal and reflection.”
        <br/>
       
<br/>
        </Typography>
    </Grid>
    <Grid item xs={12} md={4}>
             
             <Box marginBottom={4}>
               <SidebarArticles />
             </Box>
          
           {/* <SidebarNewsletter /> */}
         </Grid>
    </Grid> 
    </Container>
    </Main>
   
  );
};

export default ClassAssembly1C;